import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Power Snatch 4×4\\@75% 1RM`}</p>
    <p>{`Overhead Band Pull Aparts & Lateral Band Walks 4×8`}</p>
    <p>{`then, 3 Rounds for time of:`}</p>
    <p>{`1 Lap Run`}</p>
    <p>{`20-OHS (95/65)(RX+ 115/75)`}</p>
    <p>{`20-Bar Facing Burpees`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      